import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useInput } from "../../utils/useInput";
import GeneralForm from "./GeneralForm";
import TattooForm from "./TattooForm";
import PiercingForm from "./PiercingForm";
import ContactFormConfirmation from "./ContactFormConfirmation";
import styles from "./Contact.module.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaPhone,
  FaArrowLeft,
  FaTimes,
} from "react-icons/fa";

export const ContactForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();
  const [serviceType, serviceTypeInput, setServiceTypeInput] = useInput({
    type: "select",
    placeholder: "Choose one of the following",
    htmlFor: "serviceType",
    label: "What services are you looking for?",
    options: [
      "Choose one of the following",
      "Tattoo form",
      "Piercing form",
      "General form",
    ],
  });

  useEffect(() => {
    if (serviceType === "") {
      setServiceTypeInput("Choose one of the following");
    }
  }, [serviceType, setServiceTypeInput]);

  const renderForm = (form) => {
    switch (form) {
      case "General form":
        return <GeneralForm />;
      case "Tattoo form":
        return <TattooForm />;
      case "Piercing form":
        return <PiercingForm />;
      default:
        <></>;
    }
  };

  return (
    <>
      {!slug ? (
        <Container className={styles.contactWrap}>
          {/* {serviceType === "Choose one of the following" && ( */}
          <Row className={styles.goBack}>
            <FaTimes
              onClick={() => navigate("/", { state: { from: location } })}
            />
          </Row>
          {/* )} */}
          {/* {serviceType !== "Choose one of the following" && (
            <Row className={styles.goBack}>
              <FaArrowLeft
                onClick={() =>
                  setServiceTypeInput("Choose one of the following")
                }
              />
            </Row>
          )} */}

          <Row className={styles.innerWrap}>
            <div className={styles.left}>
              {serviceType === "Choose one of the following" && (
                <div className={styles.content}>
                  <h1>
                    Let's get <br /> in touch
                  </h1>
                  <p>
                    We're thrilled that you are reaching out to us, and we are
                    here to assist you. <br /> Please fill out the form below,
                    and we'll respond promptly. <br /> <br /> In the meantime,
                    feel free to have a look at our{" "}
                    <Link
                      to="/terms/faqs"
                      state={{ previousLocation: location }}
                    >
                      FAQs
                    </Link>
                    . Thank you for contacting us!
                  </p>
                </div>
              )}

              <div className={styles.formRow}>
                {serviceType === "Choose one of the following" ? (
                  <div className={styles.chooseForm}>{serviceTypeInput}</div>
                ) : (
                  <FaArrowLeft
                    onClick={() =>
                      setServiceTypeInput("Choose one of the following")
                    }
                  />
                )}
                {renderForm(serviceType)}
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.logo}>
                <img
                  src={require("../../../images/aba-banner-sm.png")}
                  alt="Absolute body art banner"
                />
              </div>
              <div className={styles.content}>
                <div className={styles.item}>
                  <h6 className={styles.title}>Call us</h6>
                  <Link to="tel:00441312029096">+44 131 202 9096</Link>
                </div>
                <div className={styles.item}>
                  <h6 className={styles.title}>Address</h6>
                  <p>
                    23a Blair Street <br /> EH1 1QR <br /> Edinburgh
                  </p>
                </div>

                <div className={styles.socialIcons}>
                  <Link
                    to="https://www.facebook.com/AbsoluteBodyArt/"
                    target="_blank"
                  >
                    <FaFacebookF />
                  </Link>
                  <Link
                    to="https://www.instagram.com/absolute_bodyart/"
                    target="_blank"
                  >
                    <FaInstagram />
                  </Link>
                  <Link to="tel:00441312029096">
                    <FaPhone />
                  </Link>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      ) : (
        <ContactFormConfirmation />
      )}
    </>
  );
};
