import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Row, Spinner } from "react-bootstrap";
import { useDB } from "../../../contexts/DBContext";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaTimes, FaInstagram } from "react-icons/fa";
import Carousel from "./Carousel";
import styles from "./ArtistLayout.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const ArtistLayout = () => {
  const modalRef = useRef();
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { artistCollection, artists } = useDB();
  const [artistPhotos, setArtistPhotos] = useState([]);
  const leftContentRef = useRef(null);
  const rightContentRef = useRef(null);
  const [openImg, setOpenImg] = useState(null);
  const [artistData, setArtistData] = useState(null);
  const [loadedImagesCount, setLoadedImagesCount] = useState(1);

  useEffect(() => {
    const abortController = new AbortController();
    const observerRefValue = modalRef.current;
    if (observerRefValue) {
      document.body.style.overflow = "hidden";
    }

    // Add global event listener for mouse wheel
    document.addEventListener("wheel", handleMouseWheel);

    return () => {
      document.body.style.overflow = "initial";
      abortController.abort();

      // Remove the global event listener on component unmount
      document.removeEventListener("wheel", handleMouseWheel);
    };
  }, []);

  useEffect(() => {
    if (artistCollection) {
      setArtistPhotos(
        [...artistCollection]?.filter((name) => name.artist === slug),
      );
    }
    return () => {};
  }, [slug, artistCollection]);

  useEffect(() => {
    if (artists) {
      setArtistData(artists?.filter((name) => name.ig === slug)[0]);
    }
    return () => {};
  }, [slug, artists]);

  useEffect(() => {
    // Attach the scroll event listener
    leftContentRef?.current?.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the scroll event listener on component unmount
      leftContentRef?.current?.removeEventListener("scroll", handleScroll);
    };
  }, [artistPhotos, loadedImagesCount]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = leftContentRef.current;
    const remainingImages = artistPhotos.length - loadedImagesCount;

    // Load more images when the user is at the bottom and there are remaining images
    if (remainingImages > 0 && scrollTop + clientHeight >= scrollHeight - 200) {
      const nextCount = Math.min(loadedImagesCount + 5, artistPhotos.length); // Load 5 more images at a time, adjust as needed
      setLoadedImagesCount(nextCount);
    }
  };

  // Synchronize scrolling
  const handleMouseWheel = (event) => {
    if (leftContentRef.current && rightContentRef.current) {
      const deltaY = event.deltaY;

      // Adjust the scrolling speed by multiplying deltaY with a constant factor
      const scrollSpeed = 1.25; // You can adjust this value

      // Calculate the ratio of heights
      const heightRatio =
        rightContentRef.current.scrollHeight /
        leftContentRef.current.scrollHeight;

      // Scroll both left and right sides with adjusted speed
      leftContentRef.current.scrollTop += deltaY * scrollSpeed;
      rightContentRef.current.scrollTop += deltaY * heightRatio * scrollSpeed;

      // Prevent the default scrolling behavior
      event.preventDefault();
    }
  };

  const visibleArtistPhotos = artistPhotos.slice(0, loadedImagesCount);
  console.log(visibleArtistPhotos);

  if (!artistPhotos.length || !artists.length) return <Spinner />;

  return (
    <>
      <Container className={styles.artistWrap}>
        {openImg === null ? (
          <>
            {" "}
            <Row className={styles.goBack}>
              <FaTimes
                onClick={() => {
                  setOpenImg("");
                  navigate("/", { state: { from: location } });
                }}
              />
            </Row>
            <Row className={styles.innerWrap}>
              <div className={styles.left} ref={leftContentRef}>
                {visibleArtistPhotos ? (
                  <div className={styles.grid}>
                    {artistPhotos?.length
                      ? artistPhotos?.map((artist, i) => (
                          <div key={i} className={styles.hexagon}>
                            <LazyLoadImage
                              onClick={() => setOpenImg(i)}
                              src={artist.url}
                              placeholderSrc={require("../../../images/default-sm2.png")}
                              className="img-lazy"
                              alt={artistData.ig}
                            />
                          </div>
                        ))
                      : null}
                  </div>
                ) : (
                  <Spinner />
                )}
              </div>
              <div className={styles.right} ref={rightContentRef}>
                {[artistData].length ? (
                  <div className={styles.artist}>
                    <div className={styles.imgWrapper}>
                      <div className={styles.imgWrap}>
                        <LazyLoadImage
                          src={artistData?.urlLg}
                          alt={artistData?.ig}
                          placeholderSrc={require("../../../images/default-sm2.png")}
                          className="img-lazy"
                        />
                      </div>
                      {/* Mobile */}
                      <div className={styles.nameSkill}>
                        <div className={styles.name}>
                          <h3>{artistData?.name}</h3>{" "}
                          <Link
                            target="_blank"
                            to={`https://www.instagram.com/${artistData?.ig}/`}
                          >
                            <FaInstagram />
                          </Link>
                        </div>
                        <div className={styles.skills}>
                          {artistData?.skills.map((skill, i) => (
                            <div key={i} className={styles.skill}>
                              <p>{skill}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* Mobile */}
                    </div>
                    <div className={styles.contentWrap}>
                      <div className={styles.name}>
                        <h3>{artistData?.name}</h3>{" "}
                        <Link
                          target="_blank"
                          to={`https://www.instagram.com/${artistData?.ig}/`}
                        >
                          <FaInstagram />
                        </Link>
                      </div>
                      <div className={styles.skills}>
                        {artistData?.skills.map((skill, i) => (
                          <div key={i} className={styles.skill}>
                            <p>{skill}</p>
                          </div>
                        ))}
                      </div>
                      <div className={styles.description}>
                        <p>
                          {artistData?.description?.replaceAll("\\n", "\n")}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </Row>
          </>
        ) : (
          <Carousel
            photos={artistPhotos}
            openImg={openImg}
            setOpenImg={setOpenImg}
          />
        )}
      </Container>
    </>
  );
};
