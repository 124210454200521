import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const PrivateRoute = () => {
  const { currentUser } = useAuth();
  const location = useLocation();
  return currentUser?.email ? (
    <Outlet />
  ) : (
    <Navigate to={"/admin/login"} replace state={{ from: location }} />
  );
};

export default PrivateRoute;
