import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../pages/home/Home.module.scss";
import { Link } from "react-router-dom";
import ProgressiveImg from "../pages/artist/ProgressiveImg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const InstagramComp = ({ drive, instagram }) => {
  const sliderRef = useRef(null);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    setPosts(instagram.length ? instagram : drive);
    return () => {
      setPosts([]);
    };
  }, [instagram, drive]);

  useEffect(() => {
    if (sliderRef.current && posts) {
      // @ts-ignore
      sliderRef.current?.slickGoTo(0);
    }
  }, [sliderRef, posts.length]);

  // useEffect(() => {
  //   let allPosts = [];
  //   let carouselIds = [];
  //   let carouselUrl = [];

  //   if (data) {
  //     const cAlbum = data.filter(
  //       (post) => post.media_type === "CAROUSEL_ALBUM",
  //     );
  //     cAlbum &&
  //       cAlbum.forEach((item) => {
  //         item?.children?.data?.map((item) => carouselIds.push(item.id));
  //       });
  //   }
  //   console.log(carouselIds);
  //   return () => {
  //     setPosts([]);
  //   };
  // }, [data]);

  var settings = {
    dots: false,
    arrows: true,
    infinite: posts && posts?.length >= 3 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: posts && posts?.length >= 3 ? true : false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: posts && posts?.length >= 2 ? true : false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: posts && posts?.length >= 2 ? true : false,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 688,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: false,
          autoplay: false,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          arrows: false,
          infinite: false,
        },
      },
    ],
  };

  return (
    <div style={{ display: "block" }}>
      {instagram.length ? (
        <Slider ref={sliderRef} {...settings}>
          {posts.map((item) => (
            <div key={item.id} className={styles.inner}>
              <div className={styles.imgWrap}>
                <Link to={item.permalink} target="_blank">
                  {/* <ProgressiveImg
                    src={
                      item.media_type !== "VIDEO"
                        ? item.media_url
                        : item.thumbnail_url
                    }
                    placeholderSrc={require("../../images/transparent.png")}
                    alt={`https://www.instagram.com/absolute_bodyart/`}
                  /> */}
                  <LazyLoadImage
                    src={
                      item.media_type !== "VIDEO"
                        ? item.media_url
                        : item.thumbnail_url
                    }
                    placeholderSrc={require("../../images/transparent.png")}
                    alt={`https://www.instagram.com/absolute_bodyart/`}
                    className="img-lazy"
                  />
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <Slider ref={sliderRef} {...settings}>
          {posts.map((item, i) => (
            <div key={i} className={styles.inner}>
              <div className={styles.imgWrap}>
                {/* <ProgressiveImg
                  src={item}
                  placeholderSrc={require("../../images/transparent.png")}
                  alt="https://www.instagram.com/absolute_bodyart/"
                /> */}
                <LazyLoadImage
                  src={item}
                  placeholderSrc={require("../../images/transparent.png")}
                  alt={`https://www.instagram.com/absolute_bodyart/`}
                  className="img-lazy"
                />
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default InstagramComp;
