import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function ScrollToTop({state}) {
  const {pathname} = useLocation();

  useEffect(() => {
    if (
      !pathname.includes("/contact") &&
      !pathname.includes("/artist") &&
      !pathname.includes("/terms") &&
      !state?.from
    ) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [pathname, state?.from]);

  return null;
}
