import React, { useState } from "react";
import styles from "./Input.module.scss";

export const useInput = ({
  type,
  placeholder,
  htmlFor,
  label,
  index,
  options,
}) => {
  const types = (type) => {
    switch (type) {
      case "text":
        return "";
      case "email":
        return "";
      case "number":
        return 0;
      case "checkbox":
        return false;
      case "select":
        return "";
      default:
        return "";
    }
  };

  function convertNumber(n) {
    return n <= 9 ? `0${n}` : n;
  }

  const [value, setValue] = useState(types(type));
  const input = (
    <div key={index} className={styles.inputWrap}>
      {index && <div className={styles.number}>{convertNumber(index)}</div>}
      <div className={styles.input}>
        <label className={styles.filterLabel} htmlFor={htmlFor}>
          {label}
        </label>
        {type !== "select" ? (
          type === "text" || type === "email" ? (
            <input
              value={value}
              id={htmlFor}
              onChange={(e) =>
                setValue(
                  type !== "checkbox" ? e.target.value : e.target.checked,
                )
              }
              checked={type === "checkbox" ? value : false}
              type={type}
              placeholder={placeholder}
              required
            />
          ) : (
            <textarea
              value={value}
              id={htmlFor}
              onChange={(e) =>
                setValue(
                  type !== "checkbox" ? e.target.value : e.target.checked,
                )
              }
              checked={type === "checkbox" ? value : false}
              type={type}
              rows={6}
              placeholder={placeholder}
              required
            />
          )
        ) : (
          <select
            id={htmlFor}
            defaultValue={placeholder}
            onChange={(e) => setValue(e.target.value)}
            required
          >
            <option value={placeholder} disabled hidden>
              {placeholder}
            </option>
            {options &&
              options?.sort()?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
          </select>
        )}
      </div>
    </div>
  );
  return [value, input, setValue];
};
