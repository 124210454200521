import * as React from "react";
import Signup from "./admin/Signup";
import Login from "../components/admin/Login";
import NavBar from "../components/pages/nav/NavBar";
import Footer from "../components/pages/footer/Footer";
import ForgotPassword from "../components/admin/ForgotPassword";
import AdminDashboard from "../components/admin/AdminDashboard";
import Home from "../components/pages/home/Home";
import NotFound from "../components/pages/notFound/NotFound";
import ScrollToTop from "./utils/ScrollToTop";
import {AuthProvider} from "../contexts/AuthContext";
import {useLocation, Routes, Route, Outlet} from "react-router-dom";
import "./App.scss";
import PrivateRoute from "./utils/PrivateRoute";
import UpdateProfile from "./admin/UpdateProfile";
import {DBProvider} from "../contexts/DBContext";
import Modal from "./utils/Modal";

function App() {
  const location = useLocation();
  const home = <Home key={"home"} />;
  const modal = <Modal key={"modal"} />;

  return (
    <AuthProvider>
      <DBProvider>
        <ScrollToTop state={location.state} />
        <NavBar />
        <Routes>
          <Route path="/" element={home} index />
          <Route path="/:id" element={[home, modal]} />
          <Route path="/:id/:slug" element={[home, modal]} />
          <Route path="admin/login" element={<Login />} />
          <Route path="admin/register" element={<Signup />} />
          <Route path="admin/forgot-password" element={<ForgotPassword />} />
          <Route path="admin" element={<PrivateRoute />}>
            <Route index element={<AdminDashboard />} />
            <Route path="update-profile" element={<UpdateProfile />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <Outlet />
      </DBProvider>
    </AuthProvider>
  );
}

export default App;
