import React, { useState, useEffect } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import PropTypes from "prop-types";
import styles from "./VerticalCarousel.module.scss";
import { Link } from "react-router-dom";
import ProgressiveImg from "../pages/artist/ProgressiveImg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const VerticalCarousel = ({ data }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [smBreakpoint] = useState(767);
  const [activeIndex, setActiveIndex] = useState(0);

  // #1 above. Used to determine which items appear above the active item
  const halfwayIndex = Math.ceil(data.length / 2);

  // #2 above. Used to determine the height/spacing of each item
  const itemHeight = 125;

  // #3 above. Used to determine at what point an item is moved from the top to the bottom
  const shuffleThreshold = halfwayIndex * itemHeight;

  // #4 above. Used to determine which items should be visible. Prevents "ghost" transitions
  const visibleStyleThreshold = shuffleThreshold / 1.75;

  useEffect(() => {
    const abortController = new AbortController();
    const handleWindowResize = () => setWidth(window.innerWidth);
    if (!abortController.signal.aborted) {
      window.addEventListener("resize", handleWindowResize);
    }
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      abortController.abort();
    };
  }, []);

  const handleClick = (direction) => {
    setActiveIndex((prevIndex) => {
      if (direction === "next") {
        if (prevIndex + 1 > data.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      }

      if (prevIndex - 1 < 0) {
        return data.length - 1;
      }
      return prevIndex - 1;
    });
  };

  if (!data) {
    return;
  }

  const determinePlacement = (itemIndex) => {
    // Position item in the center of list
    if (activeIndex === itemIndex) return 0;

    // Targeting items in the second part of the list
    if (itemIndex >= halfwayIndex) {
      // If moving backwards from index 0 to the last item, move the value downwards
      if (activeIndex > itemIndex - halfwayIndex) {
        return (itemIndex - activeIndex) * itemHeight;
      } else {
        // Negative value moves upwards towards the top of the list
        return -(data.length + activeIndex - itemIndex) * itemHeight;
      }
    }

    // Spacing for items after the current index
    if (itemIndex > activeIndex) {
      return (itemIndex - activeIndex) * itemHeight;
    }

    // Spacing for items before the current index
    if (itemIndex < activeIndex) {
      // If passing the negative threshold, move into a positive positioning
      if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (data.length - (activeIndex - itemIndex)) * itemHeight;
      }
      // Move into a negative positioning
      return -(activeIndex - itemIndex) * itemHeight;
    }
  };

  return (
    <div className={styles.outerContainer}>
      <div className={styles.carouselWrapper}>
        <FaChevronUp
          className={`${styles.button} ${styles.prev}`}
          onClick={() => handleClick("prev")}
        />
        <div className={styles.slides}>
          <div className={styles.thumbnailWrap}>
            {data.map((item, i) => (
              <div
                key={item.id}
                className={`${styles.imgWrap} ${
                  i === activeIndex && styles.active
                } ${
                  Math.abs(determinePlacement(i)) <= visibleStyleThreshold &&
                  styles.visible
                }`}
                style={{
                  transform:
                    width > smBreakpoint
                      ? `translateY(${determinePlacement(i)}px)`
                      : `translateX(${determinePlacement(i)}px)`,
                }}
              >
                <ProgressiveImg
                  onClick={() => setActiveIndex(i)}
                  src={item.urlSm}
                  placeholderSrc={require("../../images/transparent.png")}
                  alt={item.name}
                />
              </div>
            ))}
          </div>
        </div>
        <FaChevronDown
          className={`${styles.button} ${styles.next}`}
          onClick={() => handleClick("next")}
        />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.contentWrap}>
          <div className={styles.name}>
            <h3>{data && data[activeIndex]?.name}</h3>
          </div>
          <div className={styles.skills}>
            {data &&
              data[activeIndex]?.skills?.map((skill, i) => (
                <div key={i} className={styles.skill}>
                  <p>{skill}</p>
                </div>
              ))}
          </div>
          <div className={styles.description}>
            <p>
              {data && data[activeIndex]?.description?.replaceAll("\\n", "\n")}
            </p>
          </div>
          <Link
            to={`/artist/${data[activeIndex]?.ig}`}
            className={styles.btnPrimary}
          >
            Portfolio
          </Link>
        </div>
        <div className={styles.imgWrap}>
          <LazyLoadImage
            src={data[activeIndex]?.urlLg}
            alt={data[activeIndex]?.name}
            placeholderSrc={require("../../images/transparent.png")}
            className="img-lazy"
          />
        </div>
      </div>
    </div>
  );
};

VerticalCarousel.propTypes = {
  data: PropTypes.array.isRequired,
};

export default VerticalCarousel;
