import React, { useMemo, useState, useRef } from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import styles from "./GoogleMaps.module.scss";

const GoogleMapsComp = () => {
  const mapRef = useRef();

  const center = useMemo(() => ({ lat: 55.94909, lng: -3.18768 }), []);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  if (!isLoaded) return <div className="">Loading...</div>;

  return (
    <GoogleMap
      ref={mapRef}
      zoom={17}
      center={center}
      mapContainerClassName={styles.mapContainer}
    >
      <MarkerF position={center} onClick={() => setIsInfoOpen(true)}>
        {isInfoOpen ? (
          <InfoWindowF onCloseClick={() => setIsInfoOpen(false)}>
            <div className={styles.infoWindow}>
              <h5>
                Absolute <br /> Body Art
              </h5>
              <p>23a Blair St, Edinburgh EH1 1QR, United Kingdom</p>
              <Link to="tel:00441312029096">+44 131 202 9096</Link>
              <Button
                as={Link}
                target="_blank"
                className={styles.button}
                to="https://www.google.com/maps/dir//Absolute+Body+Art+Studio,+23a+Blair+St,+Edinburgh+EH1+1QR,+United+Kingdom/@55.9495755,-3.1877642,16.88z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4887c78ea99242e7:0xdef82d644a67731e!2m2!1d-3.1877542!2d55.9491837!3e0?entry=ttu"
              >
                get directions
              </Button>
            </div>
          </InfoWindowF>
        ) : null}
      </MarkerF>
    </GoogleMap>
  );
};

export default GoogleMapsComp;
