import React, { useRef, useState } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import styles from "./Admin.module.scss";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";

const UpdateProfile = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { updateUserEmail, updateUserPassword, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordConfirmRef.current.value !== passwordRef.current.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setError("");
    setLoading(true);
    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateUserEmail(emailRef.current.value));
    }
    if (passwordRef.current.value) {
      promises.push(updateUserPassword(passwordRef.current.value));
    }

    // if (emailRef.current.value && passwordRef.current.value) {
    //   promises.push(revokeToken(currentUser.uid));
    // }

    Promise.all(promises)
      .then(() => {
        navigate("/admin");
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container className={styles.adminWrap}>
      <Row className={styles.innerRow}>
        <Col xs={12} className={styles.loginWrap}>
          <div className={styles.header}>
            <h1>Update Profile</h1>
            {error && <Alert variant="danger">{error}</Alert>}
          </div>
          <Form onSubmit={handleSubmit} className={styles.formWrap}>
            <Form.Group as={Row} className={styles.row}>
              <Form.Group id="email" as={Col} xs={12}>
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password" as={Col} xs={12}>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordRef}
                  placeholder="Leave blank to keep the same password"
                />
              </Form.Group>
              <Form.Group id="password-confirm" as={Col} xs={12}>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordConfirmRef}
                  placeholder="Leave blank to keep the same password"
                />
              </Form.Group>
            </Form.Group>
            <Form.Group as={Row} className={styles.row}>
              <Form.Group as={Col} xs={12}>
                <Button disabled={loading} type="submit">
                  Update Profile
                </Button>
              </Form.Group>
            </Form.Group>
          </Form>
          <div className={styles.SignWrap}>
            <p>
              Do you want to cancel? <Link to={"/admin"}>Cancel</Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UpdateProfile;
