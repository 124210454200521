import React, { useState } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import styles from "./Admin.module.scss";
import { useAuth } from "../../contexts/AuthContext";
import { Link, redirect, Outlet } from "react-router-dom";

const AdminDashboard = () => {
  const { logout, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await logout();
      redirect("/admin/login");
    } catch (error) {
      console.error(error);
      setError("User couldn't be logged in...");
    }
    setLoading(false);
  };

  return (
    <Container className={styles.adminWrap}>
      <Outlet context={{ hello: "Welcome" }} />
      <Row className={styles.innerRow}>
        <Col xs={12} className={styles.loginWrap}>
          <div className={styles.header}>
            <h1>Log out</h1>
            {error && <Alert variant="danger">{error}</Alert>}
          </div>
          <Form className={styles.formWrap}>
            <Form.Group as={Row} className={styles.row}>
              <p>
                <strong>Email:</strong> {currentUser?.email}
              </p>
            </Form.Group>
            <Form.Group as={Row} className={styles.row}>
              <Form.Group as={Col} xs={12}>
                <Button
                  as={Link}
                  to={"/admin/update-profile"}
                  disabled={loading}
                  type="button"
                >
                  Update Profile
                </Button>
              </Form.Group>
            </Form.Group>
          </Form>
          <div className={styles.SignWrap}>
            <p>
              Would you like to logout?{" "}
              <Link onClick={handleSubmit}>Logout</Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminDashboard;
