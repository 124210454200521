import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {useDB} from "../../../contexts/DBContext";
import styles from "./Footer.module.scss";
import {Link, useLocation} from "react-router-dom";

const Footer = () => {
  const {pdf} = useDB();
  const location = useLocation();

  return (
    <Container fluid className={styles.footerWrap}>
      <Container className={styles.sectionWrap}>
        <Row className={styles.top}>
          <Col className={styles.wrap}>
            <div className={styles.inner}>
              <div className={styles.imgWrap}>
                <img
                  src={require("../../../images/aba-napis.png")}
                  alt="logo"
                />
              </div>
              <p>23a Blair St EH1 1QR Edinburgh</p>
            </div>
            <div className={styles.inner}>
              <h5>Contact & Openings</h5>
              <div className={styles.content}>
                <p>Tuesday - Saturday / 10:00 -18:00.</p>
                <p>Phone: 0131 202 9096</p>
                <p>Email: info@absolutebodyart.studio</p>
              </div>
            </div>
            <div className={styles.inner}>
              <h5>Downloads</h5>
              <div className={styles.content}>
                {pdf &&
                  pdf.map((item) => (
                    <Link key={item.id} target="_blank" to={item.url}>
                      {item.title}
                    </Link>
                  ))}
              </div>
            </div>
          </Col>
        </Row>
        <Row className={styles.bottom}>
          <Col className={styles.wrap}>
            <div className={styles.inner}>
              <p>
                <span>© 2022-{new Date().getFullYear()}</span> COPYRIGHT
                ABSOLUTE BODY ART
              </p>
            </div>
            <div className={styles.inner}>
              <Link to="/terms/cookies" state={{previousLocation: location}}>
                COOKIES
              </Link>
              <Link
                to="/terms/privacy-policy"
                state={{previousLocation: location}}
              >
                PRIVACY POLICY
              </Link>
              <Link
                to="/terms/terms-and-conditions"
                state={{previousLocation: location}}
              >
                TERMS & CONDITIONS
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
