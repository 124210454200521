import React, { useEffect, useRef } from "react";
import styles from "./Carousel.module.scss";

const CarouselDots = ({ photos, current, setCurrent }) => {
  const dotsWrapRef = useRef(null);
  const totalDotsToShow = 5;

  useEffect(() => {
    const activeDot = dotsWrapRef?.current?.querySelector(
      `.${styles.activeDot}`,
    );
    if (activeDot) {
      activeDot.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
    }
  }, [current]);

  useEffect(() => {
    if (current === photos.length) {
      dotsWrapRef.current.scrollLeft = 0;
      setCurrent(0);
    }
  }, [current, photos]);

  return (
    <div className={styles.dotsWrap} ref={dotsWrapRef}>
      {photos.map((_, i) => {
        const isActive = current === i;
        let isVisible = false;

        if (photos.length <= totalDotsToShow) {
          isVisible = true;
        } else {
          const start =
            (current - (totalDotsToShow - 1) + photos.length) % photos.length;
          const end = (current + totalDotsToShow) % photos.length;

          if (start < end) {
            isVisible = i >= start && i <= end;
          } else {
            isVisible = i <= end || i >= start;
          }
        }

        return (
          <div
            key={i}
            onClick={() => setCurrent(i)}
            id={isActive ? "activeDot" : ""}
            className={`${styles.dots} ${isActive ? styles.activeDot : ""} ${
              isVisible ? styles.visible : styles.notVisible
            }`}
          />
        );
      })}
    </div>
  );
};

export default CarouselDots;
