import React from "react";
import {Container, Row, Spinner} from "react-bootstrap";
import styles from "./Terms.module.scss";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {FaFacebookF, FaInstagram, FaPhone, FaArrowLeft} from "react-icons/fa";
import {faqs, cookies, privacy, termsCon} from "./TermsData";

export const Terms = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {slug} = useParams();

  const renderComp = (slug) => {
    switch (slug) {
      case "faqs":
        return faqs;
      case "cookies":
        return cookies;
      case "privacy-policy":
        return privacy;
      case "terms-and-conditions":
        return termsCon;
      default:
        <Spinner />;
    }
  };

  const renderHead = (slug) => {
    switch (slug) {
      case "faqs":
        return "FAQs";
      case "cookies":
        return "cookies";
      case "privacy-policy":
        return "privacy policy";
      case "terms-and-conditions":
        return "terms & conditions";
      default:
        <Spinner />;
    }
  };

  return (
    <Container className={styles.termsWrap}>
      <Row className={styles.goBack}>
        <FaArrowLeft
          onClick={() =>
            location ? navigate(-1) : navigate("/", {state: {from: location}})
          }
        />
      </Row>
      <Row className={styles.innerWrap}>
        <div className={styles.left}>
          <div className={styles.content}>
            {/* <h2 className={styles.head}>{renderHead(slug)}</h2> */}
            {renderComp(slug)()?.map((item) => (
              <div key={item.id} className={styles.itemWrap}>
                <h6 className={styles.title}>{item.title}</h6>
                <p className={styles.body}>{item.body}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.logo}>
            <img
              src={require("../../../images/aba-banner-sm.png")}
              alt="Absolute body art banner"
            />
          </div>
          <div className={styles.content}>
            <div className={styles.item}>
              <h6 className={styles.title}>Call us</h6>
              <Link to="tel:00441312029096">+44 131 202 9096</Link>
            </div>
            <div className={styles.item}>
              <h6 className={styles.title}>Address</h6>
              <p>
                23a Blair Street <br /> EH1 1QR <br /> Edinburgh
              </p>
            </div>
            <div className={styles.socialIcons}>
              <Link
                to="https://www.facebook.com/AbsoluteBodyArt/"
                target="_blank"
              >
                <FaFacebookF />
              </Link>
              <Link
                to="https://www.instagram.com/absolute_bodyart/"
                target="_blank"
              >
                <FaInstagram />
              </Link>
              <Link to="tel:00441312029096">
                <FaPhone />
              </Link>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};
