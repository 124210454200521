import React from "react";
import {Container, Row} from "react-bootstrap";
import styles from "./Contact.module.scss";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FaFacebookF, FaInstagram, FaPhone, FaTimesCircle} from "react-icons/fa";
import {useDB} from "../../../contexts/DBContext";

const ContactFormConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {pdf} = useDB();
  return (
    <Container className={styles.contactWrap}>
      <Row className={styles.close}>
        <FaTimesCircle
          onClick={() => navigate("/", {state: {from: location}})}
        />
      </Row>
      <Row className={styles.innerWrap}>
        <div className={styles.left}>
          <div className={styles.content}>
            <h1>
              Thank You for <br /> Contacting Us!
            </h1>
            <p>
              Your message has been successfully sent! <br /> We appreciate your
              interest and will get back to you as soon as possible. <br /> In
              the meantime, feel free to have a look at our{" "}
              <Link to="/terms/faqs" state={{previousLocation: location}}>
                FAQs
              </Link>
              . or other useful links:
            </p>
            <div className={styles.links}>
              {pdf &&
                pdf.map((item) => (
                  <Link key={item.id} target="_blank" to={item.url}>
                    {item.title}
                  </Link>
                ))}
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.logo}>
            <img
              src={require("../../../images/aba-banner-sm.png")}
              alt="Absolute body art banner"
            />
          </div>
          <div className={styles.content}>
            <div className={styles.item}>
              <h6 className={styles.title}>Call us</h6>
              <Link to="tel:00441312029096">+44 131 202 9096</Link>
            </div>
            <div className={styles.item}>
              <h6 className={styles.title}>Address</h6>
              <p>
                23a Blair Street <br /> EH1 1QR <br /> Edinburgh
              </p>
            </div>

            <div className={styles.socialIcons}>
              <Link
                to="https://www.facebook.com/AbsoluteBodyArt/"
                target="_blank"
              >
                <FaFacebookF />
              </Link>
              <Link
                to="https://www.instagram.com/absolute_bodyart/"
                target="_blank"
              >
                <FaInstagram />
              </Link>
              <Link to="tel:00441312029096">
                <FaPhone />
              </Link>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default ContactFormConfirmation;
