import React, { useRef, useState } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import styles from "./Admin.module.scss";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleError = (err) => {
    switch (err) {
      case "Firebase: Error (auth/user-not-found).":
        return "User not found";
      default:
        return "Failed to reset password";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      navigate("/admin");
    } catch (error) {
      console.error(error.message);
      setError(error?.message);
    }
    setLoading(false);
  };

  return (
    <Container className={styles.adminWrap}>
      <Row className={styles.innerRow}>
        <Col xs={12} className={styles.loginWrap}>
          <div className={styles.header}>
            <h1>Reset password</h1>
            {error && <Alert variant="danger">{handleError(error)}</Alert>}
          </div>
          <Form onSubmit={handleSubmit} className={styles.formWrap}>
            <Form.Group as={Row} className={styles.row}>
              <Form.Group id="email" as={Col} xs={12}>
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
            </Form.Group>
            <Form.Group as={Row} className={styles.row}>
              <Form.Group as={Col} xs={12}>
                <Button disabled={loading} type="submit">
                  Reset Password
                </Button>
              </Form.Group>
            </Form.Group>
          </Form>
          <div className={styles.SignWrap}>
            <p>
              Already have an account? <Link to={"/admin/login"}>Log in</Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
