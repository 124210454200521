import React, { useEffect, useRef } from "react";
import { ContactForm } from "../pages/contact/ContactForm";
import styles from "./Modal.module.scss";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ArtistLayout } from "../pages/artist/ArtistLayout";
import { Terms } from "../pages/terms/Terms";

const Modal = () => {
  const modalRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // const [width, setWidth] = useState(window.innerWidth);
  // const [smBreakpoint] = useState(767);

  // useEffect(() => {
  //   const abortController = new AbortController();
  //   const handleWindowResize = () => setWidth(window.innerWidth);
  //   if (!abortController.signal.aborted) {
  //     window.addEventListener("resize", handleWindowResize);
  //   }
  //   return () => {
  //     window.removeEventListener("resize", handleWindowResize);
  //     abortController.abort();
  //   };
  // }, [window.innerWidth]);

  useEffect(() => {
    const abortController = new AbortController();
    const observerRefValue = modalRef.current;
    if (observerRefValue) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "initial";
      abortController.abort();
    };
  }, []);

  const renderModal = (id) => {
    switch (id) {
      case "contact":
        return <ContactForm />;
      case "artist":
        return <ArtistLayout />;
      case `terms`:
        return <Terms />;
      case id:
        return (
          <div
            style={{
              minHeight: "800px",
              maxWidth: "1200px",
              width: "100%",
              backgroundColor: "whitesmoke",
            }}
          >
            {id}
          </div>
        );
      // case y:
      //   // code block
      //   break;
      default:
        <></>;
    }
  };
  return (
    <div
      ref={modalRef}
      onClick={() => navigate("/", { state: { from: location } })}
      className={styles.modalWrapper}
    >
      <div onClick={(e) => e.stopPropagation()} className={styles.inner}>
        {renderModal(id)}
      </div>
      {/* <Button className={styles.close} onClick={() => setModalOpen(false)}>
        X
      </Button> */}
    </div>
  );
};

export default Modal;
