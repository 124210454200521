import React, {useEffect, useRef, useState} from "react";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../pages/home/Home.module.scss";
import {Rating} from "react-simple-star-rating";
import {Link} from "react-router-dom";

const Testimonials = ({children}) => {
  const sliderRef = useRef(null);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    if (sliderRef.current && reviews) {
      // @ts-ignore
      sliderRef.current?.slickGoTo(0);
    }
  }, [sliderRef, reviews]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(
          "https://myfunction-6w22pqczfa-uc.a.run.app/",
        );
        setReviews(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchReviews();
  }, []);

  // useEffect(() => {
  //   const getGoogleReviews = async () => {
  //     try {
  //       const PLACE_ID = "ChIJ50KSqY7Hh0gRHnNnSmQt-N4";
  //       const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  //       const url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${PLACE_ID}&fields=reviews&key=${API_KEY}`;

  //       const response = await axios.get(url, {
  //         method: "GET",
  //         mode: "no-cors",
  //         headers: {
  //           "Access-Control-Allow-Origin": "*",
  //           "Content-Type": "application/json",
  //         },
  //         withCredentials: true,
  //         credentials: "same-origin",
  //       });

  //       const { reviews } = await response.data.result;
  //       setReviews(reviews);
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };

  //   getGoogleReviews();
  // }, []);

  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };

  if (!reviews[0]?.profile_photo_url) {
    return "loading...";
  }

  return (
    <div style={{display: "block"}}>
      {reviews ? (
        <Slider ref={sliderRef} {...settings}>
          {reviews.map((item, i) => (
            <div key={i}>
              <div className={styles.inner}>
                <div className={styles.imgWrap}>
                  {item?.profile_photo_url && (
                    <Link to={item.author_url} target="_blank">
                      <img
                        src={item?.profile_photo_url}
                        alt="google use avatar"
                      />
                    </Link>
                  )}
                </div>
                <div className={styles.contentWrap}>
                  <p className={styles.name}>{item.author_name}</p>
                  <Rating
                    initialValue={item.rating}
                    allowFraction
                    readonly
                    size={26}
                  />
                  <p className={styles.text}>{item.text}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : null}
    </div>
  );
};

export default Testimonials;
