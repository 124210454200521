import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { addDoc, collection } from "firebase/firestore";
import { useInput } from "../../utils/useInput";
import styles from "./Contact.module.scss";
import { db } from "../../../firebase";

const GeneralForm = () => {
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const contactsRef = collection(db, "contacts");
  const [error, setError] = useState("");
  const [isValidated, setIsValidated] = useState(false);

  const [name, nameInput] = useInput({
    type: "text",
    placeholder: "Type your full name",
    htmlFor: "name",
    label: "What's your name",
    index: 1,
  });
  const [email, emailInput] = useInput({
    type: "email",
    placeholder: "example@email.com",
    htmlFor: "email",
    label: "What's your email address?",
    index: 2,
  });
  const [message, messageInput] = useInput({
    type: "textarea",
    placeholder: "Please type your message",
    htmlFor: "message",
    label: "Tell us how we can help you.",
    index: 3,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await captchaRef?.current?.getValue();
    if (!!token) {
      try {
        await addDoc(contactsRef, {
          to: "info@absolutebodyart.studio",
          from: email,
          replyTo: email,
          message: {
            subject: `New message from ${name}`,
            text: message,
            html: `<div style="">
        <h3>Name: ${name}</h3>
        <p>Message: ${message}</p>
      </div>`,
          },
        });
        navigate("/contact/success");
      } catch (err) {
        console.error(err.message);
        setError("Something went wrong, please try again later");
      }
    }
    captchaRef.current.reset();
  };

  function onChange(value) {
    setIsValidated(!isValidated);
  }

  return (
    <form onSubmit={handleSubmit} className={styles.formWrap}>
      <h1>General contact form</h1>
      <div className={styles.inputs}>
        {nameInput}
        {emailInput}
        {messageInput}
      </div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_SITE_KEY}
        ref={captchaRef}
        onChange={onChange}
      />
      <button className={styles.btnPrimaryInverted} disabled={!isValidated}>
        Submit
      </button>
      {error && <p className={styles.errorMsg}>{error}</p>}
    </form>
  );
};

export default GeneralForm;
