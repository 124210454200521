import React, { useRef, useState } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import styles from "./Admin.module.scss";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";

const Signup = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwordConfirmRef.current.value !== passwordRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
    } catch (error) {
      console.error(error);
      setError("User couldn't be created...");
    }
    setLoading(false);
  };

  return (
    <Container className={styles.adminWrap}>
      <Row className={styles.innerRow}>
        <Col xs={12} className={styles.loginWrap}>
          <div className={styles.header}>
            <h1>Sign Up</h1>
            {error && <Alert variant="danger">{error}</Alert>}
          </div>
          <Form onSubmit={handleSubmit} className={styles.formWrap}>
            <Form.Group as={Row} className={styles.row}>
              <Form.Group id="email" as={Col} xs={12}>
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password" as={Col} xs={12}>
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Form.Group id="password-confirm" as={Col} xs={12}>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordConfirmRef}
                  required
                />
              </Form.Group>
            </Form.Group>
            <Form.Group as={Row} className={styles.row}>
              <Form.Group as={Col} xs={12}>
                <Button disabled={loading} type="submit">
                  Sign Up
                </Button>
              </Form.Group>
            </Form.Group>
          </Form>
          <div className={styles.SignWrap}>
            <p>
              Already have an account? <Link to={"/admin/login"}>Log in</Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Signup;
