import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { db } from "../../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { useInput } from "../../utils/useInput";
import styles from "./Contact.module.scss";

const TattooForm = () => {
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const contactsRef = collection(db, "contacts");
  const [error, setError] = useState("");
  const [isValidated, setIsValidated] = useState(false);

  const [name, nameInput] = useInput({
    type: "text",
    placeholder: "Type your full name",
    htmlFor: "name",
    label: "What's your name",
    index: 1,
  });
  const [email, emailInput] = useInput({
    type: "email",
    placeholder: "example@email.com",
    htmlFor: "email",
    label: "What's your email address?",
    index: 2,
  });
  const [over18, over18Input] = useInput({
    index: 3,
    label: "Are you over 18 years old?",
    placeholder: "",
    type: "select",
    htmlFor: "over18Select",
    options: ["yes", "no"],
  });
  const [tattooRequest, tattooRequestInput] = useInput({
    index: 4,
    label: "What is your tattoo request",
    placeholder: "",
    type: "select",
    htmlFor: "tattooRequestSelect",
    options: ["new tattoo", "cover up", "rework"],
  });
  const [tattooStyle, tattooStyleInput] = useInput({
    index: 5,
    label: "What tattoo style are you after",
    placeholder: "",
    type: "select",
    htmlFor: "tattooStyleSelect",
    options: [
      "realism",
      "dotwork",
      "traditional",
      "lettering",
      "tribe",
      "fine art",
      "geometric",
      "blackwork",
      "heavy blackwork",
      "other",
    ],
  });
  const [bodyPart, bodyPartInput] = useInput({
    type: "text",
    placeholder: "Tattoo placement",
    htmlFor: "bodyPart",
    label: "What is the desired placement for the tattoo?",
    index: 6,
  });
  const [tattooSize, tattooSizeInput] = useInput({
    type: "text",
    placeholder: "Tattoo size",
    htmlFor: "bodyPart",
    label: "What is the desired size of the tattoo?",
    index: 7,
  });
  const [message, messageInput] = useInput({
    type: "textarea",
    placeholder: "Please type your message",
    htmlFor: "message",
    label: "Brief but detailed description of your idea",
    index: 8,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await captchaRef?.current?.getValue();
    if (!!token) {
      try {
        await addDoc(contactsRef, {
          to: "info@absolutebodyart.studio",
          from: email,
          replyTo: email,
          message: {
            subject: `New message from ${name}`,
            text: message,
            html: `<div style="">
      <h3>A new message from: ${name}</h3>
      <p>Over 18?: ${over18} </p>
      <p>Request: ${tattooRequest} </p>
      <p>Style: ${tattooStyle} </p>
      <p>Placement: ${bodyPart} </p>
      <p>Size: ${tattooSize} </p>
      <p>message: ${message} </p>
    </div>`,
          },
        });
        navigate("/contact/success");
      } catch (err) {
        console.error(err.message);
        setError("Something went wrong, please try again later");
      }
    }
    captchaRef.current.reset();
  };

  function onChange(value) {
    setIsValidated(!isValidated);
  }

  return (
    <form onSubmit={handleSubmit} className={styles.formWrap}>
      <h1>Tattoo contact form</h1>
      <div className={styles.inputs}>
        {[
          nameInput,
          emailInput,
          over18Input,
          tattooRequestInput,
          tattooStyleInput,
          bodyPartInput,
          tattooSizeInput,
          messageInput,
        ]}
      </div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_SITE_KEY}
        ref={captchaRef}
        onChange={onChange}
      />
      <button className={styles.btnPrimaryInverted} disabled={!isValidated}>
        Submit
      </button>
      {error && <p className={styles.errorMsg}>{error}</p>}
    </form>
  );
};

export default TattooForm;
