import React, { useRef, useState } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import styles from "./Admin.module.scss";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, currentUser, loginWithGoogle } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      navigate("/admin");
    } catch (error) {
      console.error(error);
      setError("User couldn't be logged in...");
    }
    setLoading(false);
  };

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle();
      navigate("/admin");
    } catch (error) {
      console.error(error);
      setError("User couldn't be logged in...");
    }
  };

  return (
    <Container className={styles.adminWrap}>
      <Row className={styles.innerRow}>
        <Col xs={12} className={styles.loginWrap}>
          <div className={styles.header}>
            <h1>Login</h1>
            {currentUser?.email}
            {error && <Alert variant="danger">{error}</Alert>}
          </div>
          <Form onSubmit={handleSubmit} className={styles.formWrap}>
            <Form.Group as={Row} className={styles.row}>
              <Form.Group id="email" as={Col} xs={12}>
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password" as={Col} xs={12}>
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
            </Form.Group>
            <Form.Group as={Row} className={styles.row}>
              <Form.Group as={Col} xs={12}>
                <Button disabled={loading} type="submit">
                  Log in
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12}>
                <Button
                  onClick={handleGoogleLogin}
                  disabled={loading}
                  type="button"
                >
                  Sign In with Google
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12}>
                <Link
                  to={"/admin/forgot-password"}
                  disabled={loading}
                  type="submit"
                >
                  forgot password?
                </Link>
              </Form.Group>
            </Form.Group>
          </Form>
          <div className={styles.SignWrap}>
            <p>
              Don't have an account yet?{" "}
              <Link to={"/admin/register"}>Sign up</Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
