import React, {useContext, useState, useEffect} from "react";
import axios from "axios";
import {db, storage} from "../firebase";
import {getDocs, collection} from "firebase/firestore";
import {ref, listAll, getDownloadURL} from "firebase/storage";

const DBContext = React.createContext();

export function useDB() {
  return useContext(DBContext);
}

export function DBProvider({children}) {
  const [loading, setLoading] = useState(true);
  const [artists, setArtists] = useState([]);
  const [instagramPhotos, setInstagramPhotos] = useState([]);
  const [artistCollection, setArtistCollection] = useState([]);
  const [pdf, setPdf] = useState([]);
  const [instagramPosts, setInstagramPosts] = useState([]);

  const artistCollectionRef = collection(db, "artists");
  const instagramFilesRef = ref(storage, "instagram/");
  const pdfFilesRef = collection(db, "downloads");

  useEffect(() => {
    const accessToken = process.env.REACT_APP_IG_ACCESS_TOKEN;
    const userId = process.env.REACT_APP_IG_USER_ID;

    const apiUrl = `https://graph.instagram.com/${userId}/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,username,is_shared_to_feed,children&access_token=${accessToken}`;

    axios
      .get(apiUrl)
      .then((response) => {
        setInstagramPosts(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching Instagram posts:", error);
      });
  }, []);

  // console.log(instagramPosts);
  // console.log(
  //   instagramPosts.filter((post) => post.caption?.includes("insomnia_gleart")),
  // );

  useEffect(() => {
    const getArtistList = async () => {
      try {
        setLoading(true);
        const data = await getDocs(artistCollectionRef);
        const filteredData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setArtists(filteredData.sort((a, b) => a?.order - b?.order));
      } catch (error) {
        console.error(error);
        throw new Error(error);
      }
      setLoading(false);
    };

    !artists.length && getArtistList();
  }, [artistCollectionRef, artists]);

  useEffect(() => {
    const getInstagramPhotos = async () => {
      const isPhotos = [];
      try {
        setLoading(true);
        listAll(instagramFilesRef).then((res) => {
          for (let i = 0; i < res.items.length; i++) {
            getDownloadURL(res.items[i]).then((url) => {
              isPhotos.push(url);
            });
          }
        });
        setInstagramPhotos(isPhotos);
      } catch (error) {
        console.error(error);
        throw new Error(error);
      }
      setLoading(false);
    };

    !instagramPhotos.length && !loading && getInstagramPhotos();
  }, [loading]);

  useEffect(() => {
    artists?.forEach((item) => {
      const getArtistPhotos = async () => {
        try {
          setLoading(true);
          listAll(ref(storage, `artistsPortfolio/${item.ig}`)).then((res) => {
            let artist = item.ig;
            res.items.forEach((item) => {
              getDownloadURL(item).then((url) => {
                setArtistCollection((prev) => [...prev, {url, artist}]);
              });
            });
          });
        } catch (error) {
          console.error(error);
          throw new Error(error);
        }
        setLoading(false);
      };

      !artistCollection.length && getArtistPhotos();
    });
  }, [artists, artistCollection]);

  useEffect(() => {
    const getPdfs = async () => {
      try {
        setLoading(true);
        const data = await getDocs(pdfFilesRef);
        const filteredData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setPdf(filteredData);
      } catch (error) {
        console.error(error);
        throw new Error(error);
      }
      setLoading(false);
    };

    !pdf.length && getPdfs();
  }, [pdfFilesRef, pdf]);

  // function signup(email, password) {
  //   return createUserWithEmailAndPassword(auth, email, password);
  // }

  // useEffect(() => {
    // const accessToken = process.env.REACT_APP_IG_ACCESS_TOKEN;
    // const userId = process.env.REACT_APP_IG_USER_ID;
  //   const batchSize = 25;
  //   const desiredTotalPosts = 100;
  //   let apiUrl = `https://graph.instagram.com/${userId}/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,username,is_shared_to_feed,children&access_token=${accessToken}`;

  //   const fetchPosts = async () => {
  //     try {
  //       let allPosts = [];

  //       while (apiUrl) {
  //         const response = await axios.get(apiUrl);
  //         allPosts = [...allPosts, ...response.data.data];
  //         apiUrl = response.data.paging.next;

  //         // Break the loop if you want to limit the total number of posts fetched.
  //         if (allPosts.length >= desiredTotalPosts) {
  //           break;
  //         }
  //       }

  //       setInstagramPosts(allPosts);
  //     } catch (error) {
  //       console.error("Error fetching Instagram posts:", error);
  //     }
  //   };

  //   fetchPosts();
  // }, []);

  const value = {
    pdf,
    artists,
    instagramPhotos,
    instagramPosts,
    artistCollection,
  };

  return <DBContext.Provider value={value}>{children}</DBContext.Provider>;
}
