import React, { useState, useEffect } from "react";
const ProgressiveImg = ({ placeholderSrc, src, onClick, alt }) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  const customClass =
    placeholderSrc && imgSrc === placeholderSrc
      ? "progressive-img-loading"
      : "progressive-img-loaded";

  return (
    <img
      onClick={onClick}
      src={imgSrc}
      alt={alt}
      className={`progressive-image ${customClass}`}
    />
  );
};
export default ProgressiveImg;
