import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaFacebookF, FaInstagram, FaPhone } from "react-icons/fa";
import { useDB } from "../../../contexts/DBContext";
import styles from "./Home.module.scss";
import VerticalCarousel from "../../comp/VerticalCarousel";
import InstagramComp from "../../comp/InstagramComp";
import Testimonials from "../../comp/Testimonials";
import { Link, useLocation } from "react-router-dom";
import GoogleMapsComp from "../../comp/GoogleMapsComp";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Home = () => {
  const location = useLocation();
  const { artists, instagramPhotos, instagramPosts } = useDB();
  const [openImg, setOpenImg] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [smBreakpoint] = useState(767);

  function scrollToSection(id) {
    const el = document.getElementById(id);
    el?.scrollIntoView({
      behavior: "smooth",
      block: width > smBreakpoint ? "center" : "start",
      inline: "nearest",
    });
  }

  useEffect(() => {
    const abortController = new AbortController();
    const handleWindowResize = () => setWidth(window.innerWidth);
    if (!abortController.signal.aborted) {
      window.addEventListener("resize", handleWindowResize);
    }
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      abortController.abort();
    };
  }, []);

  return (
    <>
      <Container fluid className={styles.homeWrapper}>
        <div id="home" className={styles.heroBG}>
          <Container className={styles.sectionWrap}>
            <Row className={styles.heroRow}>
              <Col className={styles.heroWrap}>
                <div className={styles.contentWrap}>
                  <h1>
                    Absolute <br /> Body Art
                  </h1>
                  <p>
                    We are a renowned tattoo and piercing studio in Edinburgh,
                    home to a team of exceptionally skilled and dedicated
                    artists.
                  </p>
                </div>
                <div className={styles.buttonWrap}>
                  <Button
                    className={styles.btnPrimary}
                    onClick={() => scrollToSection("contact")}
                  >
                    Get in touch
                  </Button>
                  <Button
                    className={styles.btnPrimary}
                    onClick={() => scrollToSection("artists")}
                  >
                    Artists
                  </Button>
                </div>
              </Col>
              <div className={styles.socialWrap}>
                <div className={styles.line}></div>
                <div className={styles.content}>
                  <Link
                    to="https://www.facebook.com/AbsoluteBodyArt/"
                    target="_blank"
                  >
                    <FaFacebookF />
                  </Link>
                  <Link
                    to="https://www.instagram.com/absolute_bodyart/"
                    target="_blank"
                  >
                    <FaInstagram />
                  </Link>
                  <Link to="tel:00441312029096">
                    <FaPhone />
                  </Link>
                </div>
                <div className={styles.line}></div>
              </div>
            </Row>
          </Container>
        </div>
        <Container className={styles.sectionWrap}>
          <div className={styles.spacer} />

          <Row className={styles.aboutWrap}>
            <h5 className={styles.title}>About us</h5>
            <Col className={styles.wrap}>
              <div className={styles.imgWrap}>
                <LazyLoadImage
                  src={require("../../../images/about-photograph.jpg")}
                  placeholderSrc={require("../../../images/transparent.png")}
                  alt={`https://www.instagram.com/absolute_bodyart/`}
                  className="img-lazy"
                />
              </div>
              <div className={styles.contentWrap}>
                <div className={styles.head}>
                  <h2>Who We Are</h2>
                </div>
                <div className={styles.para}>
                  <p>
                    Our artists specialize in a wide range of styles. From
                    delicate and intricate linework to bold and vibrant designs.{" "}
                  </p>

                  <p>
                    Whether you are looking for a small, minimalist tattoo or a
                    large, detailed piece, we have the skills and experience to
                    make your dream tattoo a reality.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <div className={styles.spacer} />
          <Row className={styles.instagramWrap}>
            <Col className={styles.wrap}>
              <div className={styles.contentWrap}>
                <div className={styles.logo}>
                  <LazyLoadImage
                    src={require("../../../images/aba-banner-sm.png")}
                    placeholderSrc={require("../../../images/transparent.png")}
                    alt="Absolute body art banner"
                    className="img-lazy"
                  />
                </div>
                <div className={styles.content}>
                  <h5>@absolute_bodyart</h5>
                  <p>1,889 followers</p>
                </div>
                <div className={styles.buttonWrap}>
                  <Button
                    as={Link}
                    target="_blank"
                    to="https://www.instagram.com/absolute_bodyart/"
                    className={styles.btnPrimary}
                  >
                    Follow
                  </Button>
                </div>
              </div>
              <div className={styles.carouselWrap}>
                <InstagramComp
                  instagram={instagramPosts}
                  drive={instagramPhotos}
                />
              </div>
            </Col>
          </Row>

          <div className={styles.spacer} />

          <Row id="artists" className={styles.artistWrap}>
            <Col className={styles.wrap}>
              <h5 className={styles.title}>Artists</h5>
              <VerticalCarousel data={artists} />
            </Col>
          </Row>

          <div className={styles.spacer} />

          <Row className={styles.testimonialWrap}>
            <Col className={`${styles.wrap} testimonial-carousel`}>
              <Testimonials />
            </Col>
          </Row>

          <div className={styles.spacer} />

          <Row id="contact" className={styles.contactWrap}>
            <Col className={styles.wrap}>
              <h5 className={styles.title}>Contact</h5>
              <div className={styles.contact}>
                <div className={styles.inner}>
                  <h5>Can we help?</h5>
                  <p>
                    You are reachable on <span>0131 202 9096</span> from Tuesday
                    to Saturday between the hours of 10 am and 6 pm. <br /> 23a
                    Blair St EH1 1QR Edinburgh
                  </p>
                  <Button
                    as={Link}
                    to="/contact"
                    state={{ previousLocation: location }}
                    className={styles.btnPrimary}
                  >
                    Contact us
                  </Button>
                </div>
              </div>
              <div className={styles.map}>
                <GoogleMapsComp />
              </div>
            </Col>
          </Row>

          <div className={styles.spacer} />
        </Container>
      </Container>
    </>
  );
};

export default Home;
